import request from '@/api/request.js'

/**
 * @Description: 盘点单
 */

// 分页查询
export function pageWmsInventoryCheck(data) {
    return request({
        url: '/api/wmsInventoryCheck/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsInventoryCheck(data) {
    return request({
        url: '/api/wmsInventoryCheck/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsInventoryCheck(data) {
    return request({
        url: '/api/wmsInventoryCheck/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsInventoryCheck(data) {
    return request({
        url: '/api/wmsInventoryCheck/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsInventoryCheck(data) {
    return request({
        url: '/api/wmsInventoryCheck/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsInventoryCheck(data) {
    return request({
        url: '/api/wmsInventoryCheck/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsInventoryCheck(data) {
    return request({
        url: '/api/wmsInventoryCheck/batchDelete',
        method: 'POST',
        data
    })
}

// 完成盘点
export function completeWmsInventoryCheck(data) {
    return request({
        url: '/api/wmsInventoryCheck/complete',
        method: 'POST',
        data
    })
}

// 审核通过
export function confirmWmsInventoryCheck(data) {
    return request({
        url: '/api/wmsInventoryCheck/confirm',
        method: 'POST',
        data
    })
}

// 审核不通过
export function rejectWmsInventoryCheck(data) {
    return request({
        url: '/api/wmsInventoryCheck/reject',
        method: 'POST',
        data
    })
}

